@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');



@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root,
.app{
    height: 100%;
    background-color: #ffff;
    @apply text-black;
}